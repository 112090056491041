<template>
    <div :class="s.container">
        <div :class="s.center">
            <p @click.prevent="$router.go(-1)" :class="s.back">← Go back</p>
            <div :class="s.content" v-if="!isSecondStep">
                <div :class="[s.row, s.header, s.w100]">
                    <span :class="s.headerText">
                        Step 1 / 2: Whitelist your address
                    </span>
                </div>
                <div :class="[s.row, s.info]">
                    <Icon icon="info" :class="s.mrXs" />
                    <span :class="s.infoText">
                        To add an external wallet address to the whitelist, you need to complete all the fields below and make a deposit
                    </span>
                </div>
                <div :class="[s.row, s.alignCenter, s.gap, s.mainPadding, s.mbXl]">
                    <div :class="s.w50">
                        <TextInput
                            :class="s.w100"
                            title="Address Name"
                            @input-text="name = $event; errors.name = false"
                            :value="name"
                            :has-error="errors.name"
                            error-text="Address name min length is 3 letters.<br>Address max length is 20 letters."
                        />
                    </div>
                    <div :class="s.w50">
                        <TextInput
                            :class="s.w100"
                            title="Address"
                            @input-text="address = $event; errors.address = false"
                            :value="address"
                            :has-error="errors.address"
                            error-text="Address is required"
                        />
                    </div>
                </div>
                <div :class="[s.row, s.alignCenter, s.gap, s.mainPadding, s.mbXl]">
                    <div :class="s.w50">
                        <AssetsBlockchainsDropdown
                            v-if="filteredBlockchainsList && filteredBlockchainsList.length > 0 && filteredBlockchainsList[activeWhitelistingBlockchainIndex]"
                            is-blockchains
                            :elements="filteredBlockchainsList.length > 0 ? filteredBlockchainsList.map(({ name }) => name) : ['No available networks']"
                            title="Network"
                            :active-item-index="activeWhitelistingBlockchainIndex"
                            @select-element="onSelectBlockchain($event)"
                        />
                    </div>
                    <div :class="s.w50">
                        <AssetsBlockchainsDropdown
                            v-if="whitelistingAssetsList && whitelistingAssetsList.length > 0"
                            :elements="whitelistingAssetsList.length > 0 ? whitelistingAssetsList.map(({ symbol }) => symbol) : ['No available assets']"
                            title="Currency"
                            :active-item-index="activeWhitelistingAssetIndex"
                            @select-element="onSelectAsset($event)"
                        />
                    </div>
                </div>
                <div :class="[s.row, s.mainPadding]">
                    <money-input
                        title="Deposit Amount"
                        :currency="whitelistingAssetsList[activeWhitelistingAssetIndex].symbol"
                        v-model="amount"
                        :validations="$v.amount"
                        :min-value="quantityMinValue"
                        :max-precision="8"
                        :show-tooltip="showTooltip"
                        :is-external-error="errors.amount"
                        :external-error-text="`The amount cannot be less than ${quantityMinValue}`"
                    />
                </div>
                <div :class="s.quotationRow">
                    <span :class="s.quotationText">
                        ~{{ quotationAssetCharacter }} {{ (amount * quotationRate).floor(quotationAssetPrecision).noExponents().getSpaceSeparatedDigits() }}
                    </span>
                </div>
                <div :class="[s.row, s.mainPadding, s.mbXl]">
                    <Checkbox
                        :value="isUserAgree"
                        @state-changed="isUserAgree = !isUserAgree"
                        :class="s.bottom"
                    >
                        <template>
                        <span :class="[s.conditionsText, { [s.orange]: errors.isUserAgree }]">
                            I've double-checked the currency, network, address, quantity, fee, and agree with
                            <a :class="s.link" href="https://www.singlebroker.com/terms-and-conditions" target="_blank">
                                Terms & Conditions of SingleBroker GmbH
                            </a>
                        </span>
                        </template>
                    </Checkbox>
                </div>
                <div :class="[s.row, s.buttonsPadding, s.gap]">
                    <Button
                        :button-disabled="!isKycVerified"
                        @click="$router.push(previousRoute)"
                        button-type="info"
                        wide
                    >
                        <template>
                            reject
                        </template>
                    </Button>
                    <Button
                        :button-disabled="!isKycVerified"
                        @click="showSecondStep"
                        button-type="primary"
                        wide
                    >
                        <template>
                            start
                        </template>
                    </Button>
                </div>
            </div>
            <div :class="s.content" v-else>
                <div :class="[s.row, s.header, s.w100]">
                    <span :class="s.headerText">
                        Step 2 / 2: Whitelist your address
                    </span>
                </div>
                <div :class="[s.row, s.info]">
                    <Icon icon="info" :class="s.mrXs" />
                    <span :class="s.infoText">
                        Deposit only from your external wallet with whitelisted address.
                        Verification is not paid, you will receive funds on your deposit.
                    </span>
                </div>
                <div :class="[s.row, s.w100, s.mainPadding]">
                    <div :class="[s.detailsContainer]">
                        <div v-if="!showQr">
                            <div :class="[s.row, s.mbL]">
                                <span :class="s.subheader">
                                    Details
                                </span>
                            </div>
                            <div :class="[s.row, s.details]">
                                <div :class="s.w100">
                                    <p :class="s.titleText">Currency</p>
                                    <p :class="[s.valueText, s.row, s.alignCenter]">
                                        <CryptoIcon
                                            v-if="whitelistingAssetsList && whitelistingAssetsList[activeWhitelistingAssetIndex]"
                                            :symbol="whitelistingAssetsList[activeWhitelistingAssetIndex].symbol"
                                            :size="12"
                                            :class="s.mrXs"
                                        />
                                        <span>{{ whitelistingAssetsList && whitelistingAssetsList[activeWhitelistingAssetIndex] ? whitelistingAssetsList[activeWhitelistingAssetIndex].symbol : '' }}</span>
                                    </p>
                                </div>
                                <div :class="s.w100">
                                    <p :class="s.titleText">Amount</p>
                                    <p>
                                        <AssetValue
                                            :asset="whitelistingAssetsList && whitelistingAssetsList[activeWhitelistingAssetIndex] ? whitelistingAssetsList[activeWhitelistingAssetIndex].symbol : ''"
                                            :value="amount"
                                            :need-to-round="false"
                                            :value-class="s.valueText"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div :class="[s.row, s.details]">
                                <div :class="s.w100">
                                    <p :class="s.titleText">Network</p>
                                    <p :class="[s.row, s.alignCenter, s.valueText]">
                                        <CryptoIcon
                                            v-if="filteredBlockchainsList && filteredBlockchainsList[activeWhitelistingBlockchainIndex]"
                                            :symbol="filteredBlockchainsList[activeWhitelistingBlockchainIndex].name"
                                            is-blockchain
                                            :class="s.mrXs"
                                            :size="12"
                                        />
                                        <span>{{ filteredBlockchainsList && filteredBlockchainsList[activeWhitelistingBlockchainIndex] ? filteredBlockchainsList[activeWhitelistingBlockchainIndex].name : '' }}</span>
                                    </p>
                                </div>
                                <div :class="s.w100">
                                    <p :class="s.titleText">Verification</p>
                                    <p>
                                        <AssetValue
                                            :value="verificationAmount"
                                            :asset="whitelistingAssetsList ? whitelistingAssetsList[activeWhitelistingAssetIndex].symbol : ''"
                                            :need-to-round="false"
                                            :value-class="s.valueText"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div :class="[s.row, s.details]">
                                <div :class="s.w100">
                                    <p :class="s.titleText">Whitelisted Address</p>
                                    <p :class="[s.valueText, s.breakWord]">{{ address }}</p>
                                </div>
                            </div>
                            <div :class="[s.row, s.details]">
                                <div :class="s.w100">
                                    <p :class="s.titleText">Wallet Name</p>
                                    <p :class="[s.valueText, s.breakWord]">{{ name }}</p>
                                </div>
                            </div>
                        </div>
                        <div :class="[s.row, s.mbL]" v-else>
                            <vue-qr
                                v-if="destinationAddress"
                                :class="s.qr"
                                :background-color="'#F0F1FB'"
                                :color-dark="'#656FDB'"
                                :color-light="'#F0F1FB'"
                                :text="destinationAddress && destinationAddress.memo ? `address=${destinationAddress.address}?memo=${destinationAddress.memo}` : `address=${destinationAddress.address}`"
                            />
                        </div>
                        <div :class="[s.row, s.detailsContainer, s.internal]">
                            <div :class="s.w100">
                                <div :class="[s.row, s.details, s.start]">
                                    <div :class="s.w100">
                                        <p :class="s.titleText">Total Amount</p>
                                        <p
                                            :class="[s.pointer, s.copyRow]"
                                            @click="copyTextWithPermissions(String(totalQuantity), $store.dispatch, 'Total amount')"
                                        >
                                            <AssetValue
                                                :value="totalQuantity"
                                                :asset="whitelistingAssetsList && whitelistingAssetsList[activeWhitelistingAssetIndex] ? whitelistingAssetsList[activeWhitelistingAssetIndex].symbol : ''"
                                                :need-to-round="false"
                                                :value-class="s.valueText"
                                            />
                                            <span :class="s.copyIconWrapper">
                                                <Icon
                                                    icon="copy"
                                                    :class="s.copyIcon"
                                                />
                                            </span>
                                        </p>
                                    </div>
                                    <div :class="s.w100">
                                        <p :class="s.titleText">Value</p>
                                        <p
                                            :class="s.gridMargin"
                                        >
                                            <AssetValue
                                                :value="totalQuantity * quotationRate"
                                                :asset="quotationAssetSymbol"
                                                :precision="quotationAssetPrecision"
                                                :value-class="s.valueText"
                                            />
                                        </p>
                                    </div>
                                </div>
                                <div :class="[s.row, s.details, s.last]">
                                    <div :class="s.w100">
                                        <p :class="s.titleText">Deposit Address</p>
                                        <div
                                            @click="copyTextWithPermissions(destinationAddress ? destinationAddress.address : '', $store.dispatch, 'Address')"
                                            :class="[s.copyRow, s.valueText, s.breakWord, s.pointer]"
                                        >
                                            <p>
                                                {{ destinationAddress ? destinationAddress.address : '' }}
                                            </p>
                                            <div :class="s.copyIconWrapper">
                                                <Icon
                                                    icon="copy"
                                                    :class="s.copyIcon"
                                                />
                                            </div>
                                        </div>
                                        <div :class="s.buttonsRow">
                                            <Button
                                                @click="showQr = !showQr"
                                                button-type="primary"
                                                :class="s.button"
                                            >
                                                <template>
                                                    <div :class="s.buttonRow">
                                                        <Icon icon="qr" />
                                                        qr-code
                                                    </div>
                                                </template>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[s.row, s.w100, s.gap, s.justifyContentBetween, s.mtL, s.buttonPadding]">
                    <Button
                        :button-disabled="!isKycVerified"
                        @click="cancelTransfer(transferId)"
                        button-type="info"
                        wide
                    >
                        <template>
                            REJECT
                        </template>
                    </Button>
                    <Button
                        :button-disabled="!isKycVerified"
                        @click="$router.push(previousRoute)"
                        button-type="primary"
                        wide
                    >
                        <template>
                            COMPLETE
                        </template>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import { minValue, required, sameAs } from 'vuelidate/dist/validators.min';
import { maxLength, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { getManifest, hasManifest } from 'Models/assetsManifest';
import WalletsApi from 'Apis/Wallets';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import CreateDepositRequestData from 'Entities/walletExecutor/CreateDepositRequestData';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Asset from 'Entities/publicPresenter/Asset';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Button from 'Control/Button.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import TextInput from 'Control/TextInput.vue';
import Icon from 'UI/Icon.vue';
import Checkbox from 'Control/Checkbox.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import MoneyInput from 'Control/MoneyInput.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

export default {
    components: {
        AssetValue,
        VueQr,
        Button,
        AssetsBlockchainsDropdown,
        TextInput,
        Icon,
        Checkbox,
        MoneyInput,
    },
    data() {
        return {
            hasManifest,
            getManifest,
            amount: 0,
            name: '',
            address: '',
            activeWhitelistingBlockchainIndex: 0,
            activeWhitelistingAssetIndex: 0,
            isSecondStep: false,
            showQr: false,
            time: null,
            destinationAddress: null,
            totalQuantity: 0,
            transferId: null,
            isUserAgree: false,
            hasCheckboxError: false,
            showTooltip: true,
            previousRoute: '/wallets',
            copyTextWithPermissions,
            quotationRate: 0,
            lastQuotationRequest: {
                quotationSymbol: '',
                symbol: '',
            },
            errors: {
                isUserAgree: false,
                amount: false,
                address: false,
                name: false,
            },
        };
    },
    validations() {
        return {
            isUserAgree: {
                sameAs: sameAs(() => true),
            },
            amount: {
                minValue: minValue(this.quantityMinValue),
            },
            address: {
                required,
            },
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
        }),
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        verificationAmount() {
            let precision = 0;
            if (String(this.totalQuantity).split('.')[1]) {
                precision = String(this.totalQuantity).split('.')[1].length;
            }
            return (this.totalQuantity - this.amount).toFixed(precision);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        currenciesList() {
            return this.$route.query.assets.map((a) => new Asset(a));
        },
        whitelistingBlockchainsList() {
            return this.$route.query.allBlockchains.map((b) => new Blockchain(b));
        },
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        whitelistingAssetsList() {
            if (this.filteredBlockchainsList && this.filteredBlockchainsList.length > 0) {
                return [{ symbol: this.filteredBlockchainsList[this.activeWhitelistingBlockchainIndex].nativeAssetSymbol }];
            }
            return [{ symbol: '' }];
        },
        activeAsset() {
            if (this.whitelistingAssetsList && this.whitelistingAssetsList.length && this.whitelistingAssetsList[this.activeWhitelistingAssetIndex]) {
                return this.whitelistingAssetsList[this.activeWhitelistingAssetIndex].symbol;
            }
            return '';
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        activeAccount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.id === this.activeAccountId);
        },
        filteredBlockchainsList() {
            return this.whitelistingBlockchainsList.filter((b) => {
                return this.currenciesList.some((c) => c.symbol === b.nativeAssetSymbol);
            });
        },
        quantityMinValue() {
            const details = this.currenciesList?.find(({ symbol }) => symbol === this.whitelistingAssetsList[0].symbol)?.transferDetails;
            const detail = details?.find(({ source, destination, blockchainName }) => source === 'Single Broker'
                && destination === 'Single Broker'
                && blockchainName === this.filteredBlockchainsList[this.activeWhitelistingBlockchainIndex].name);
            return detail?.transferMinSize ?? 0;
        },
    },
    methods: {
        async getQuotationRate() {
            if (!this.activeAsset || !this.quotationAssetSymbol) {
                this.quotationRate = 0;
            }

            try {
                // check for not duplicating requests
                if (
                    this.lastQuotationRequest.quotationSymbol === this.quotationAssetSymbol
                    && this.lastQuotationRequest.symbol === this.activeAsset
                ) {
                    return;
                }
                this.lastQuotationRequest = {
                    quotationSymbol: this.quotationAssetSymbol,
                    symbol: this.activeAsset,
                };
                const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest(this.lastQuotationRequest));
                this.quotationRate = quotation.rate;
            } catch {
                this.quotationRate = 0;
            }
        },
        onSelectBlockchain(index) {
            if (index !== this.activeWhitelistingBlockchainIndex) {
                this.address = '';
                this.name = '';
            }
            this.activeWhitelistingBlockchainIndex = index;
        },
        onSelectAsset(index) {
            if (index !== this.activeWhitelistingAssetIndex) {
                this.address = '';
                this.name = '';
            }
            this.activeWhitelistingAssetIndex = index;
        },
        showNotification() {
            this.$store.dispatch('Notificator/showSuccessNotification', 'Value Has Successfully Copied To Your Clipboard');
        },
        async showSecondStep() {
            this.$v.$touch();
            this.hasCheckboxError = !this.isUserAgree;
            if (!this.$v.$invalid) {
                if (this.activeAccount[0].placementLinks && this.activeAccount[0].placementLinks.find((l) => l === 'Single Broker')) {
                    try {
                        this.$store.commit(SET_LOADING_ON(undefined));
                        this.destinationAddress = await this.getAddress();
                        this.totalQuantity = await this.getTransferData();
                        this.isSecondStep = true;
                    } finally {
                        this.$store.commit(SET_LOADING_OFF(undefined));
                    }
                } else {
                    this.showErrorNotification();
                }
            } else {
                setTimeout(() => {
                    this.showTooltip = true;
                }, 100);

                if (this.$v.name.$invalid) {
                    this.errors.name = true;
                }
                if (this.$v.address.$invalid) {
                    this.errors.address = true;
                }
                if (this.$v.amount.$invalid) {
                    this.errors.amount = true;
                }
                if (this.$v.isUserAgree.$invalid) {
                    this.errors.isUserAgree = true;
                }
            }
        },
        showErrorNotification() {
            this.$store.dispatch('Notificator/showErrorNotification', 'Your Account Is Still In Progress');
        },
        async getAddress() {
            try {
                const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                    accountId: this.activeAccountId,
                    assetSymbol: this.whitelistingAssetsList[this.activeWhitelistingAssetIndex].symbol,
                    blockchainName: this.filteredBlockchainsList[this.activeWhitelistingBlockchainIndex].name,
                }));
                return res;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting address');
                }
            }
        },
        async getTransferData() {
            try {
                const { data: res } = await WalletsApi.createDeposit(new CreateDepositRequestData({
                    accountId: this.activeAccountId,
                    address: this.address,
                    addressAlias: this.name,
                    assetSymbol: this.whitelistingAssetsList[this.activeWhitelistingAssetIndex].symbol,
                    blockchainName: this.filteredBlockchainsList[this.activeWhitelistingBlockchainIndex].name,
                    amount: this.amount,
                }));
                const { transferId } = res;
                this.transferId = transferId;
                const { data: resp } = await WalletsApi.privateGetTransfer(new TransferRequest({
                    id: transferId,
                }));
                return resp.quantity;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting transfer data');
                }
            }
        },
        setTimer(time) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            if (time) {
                if (time < 900000) {
                    this.time = 900000 - time;
                } else {
                    this.time = 0;
                }
            } else {
                this.time = 900000;
            }
            this.intervalId = setInterval(() => {
                if (this.time === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                } else {
                    this.time -= 1000;
                }
            }, 1000);
        },
        validateTimeNumber(num) {
            if (Number(num) < 10) {
                return `0${num}`;
            }
            return String(num);
        },
        async cancelTransfer(id) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
        closeTooltip() {
            this.showTooltip = false;
        },
    },
    async mounted() {
        document.addEventListener('click', this.closeTooltip);

        const {
            previousRoute,
            selectedAsset,
            selectedBlockchain,
        } = this.$route.query;
        this.previousRoute = previousRoute;
        if (selectedBlockchain && selectedAsset) {
            const blockchainIndex = this.filteredBlockchainsList.findIndex(({ name }) => name === selectedBlockchain);
            if (blockchainIndex !== undefined && blockchainIndex !== -1) {
                this.activeWhitelistingBlockchainIndex = blockchainIndex;
            }
            const assetIndex = this.whitelistingAssetsList.findIndex(({ symbol }) => symbol === selectedAsset);
            if (assetIndex !== undefined && assetIndex !== -1) {
                this.activeWhitelistingAssetIndex = assetIndex;
            }
        }
        await this.getQuotationRate();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeTooltip);
    },
    watch: {
        amount() {
            this.errors.amount = false;
        },
        isUserAgree() {
            this.errors.isUserAgree = false;
        },
        async activeAsset() {
            await this.getQuotationRate();
        },
        async quotationAssetSymbol() {
            await this.getQuotationRate();
        },
        quotationRate(v) {
            console.log('q', v);
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: relative;
    width: 100%;
    height: 100%;
    & .center {
        position: absolute;
        left: 50%;
        top: calc(2 * var(--m-xxxl));
        transform: translate(-50%, 0);
        width: 400px;
        & .back {
            cursor: pointer;
            margin-bottom: var(--m-l);
            font-weight: var(--fw-bold);
            font-size: var(--fs-l);
            line-height: var(--fs-l);
            color: var(--cl-violet);
        }
        & .content {
            border-radius: 8px;
            background: var(--cl-white);
            & .headerText {
                font-weight: var(--fw-bold);
                font-size: var(--fs-xl);
                line-height: var(--fs-xl);
                text-transform: uppercase;
                color: var(--cl-black);
                & .violet {
                    color: var(--cl-violet);
                }
            }
            & .infoText {
                font-weight: var(--fw-regular);
                font-size: var(--fs-l);
                line-height: var(--fs-l);
                color: var(--cl-gray);
            }
            & .conditionsText {
                font-weight: var(--fw-regular);
                font-size: var(--fs-s);
                line-height: var(--fs-s);
                color: var(--cl-black);
                & .link {
                    color: var(--cl-violet);
                }
                &.orange {
                    color: var(--cl-header-orange-2);
                }
            }
            & .titleText {
                font-weight: var(--fw-semibold);
                font-size: var(--fs-s);
                line-height: var(--fs-s);
                color: var(--cl-gray);
                margin-bottom: var(--m-xs);
            }
            & .valueText {
                font-weight: var(--fw-regular);
                font-size: var(--fs-l);
                color: var(--cl-black);
                &.breakWord {
                    word-break: break-word;
                }
            }
            & .subheader {
                font-weight: var(--fw-bold);
                font-size: var(--fs-xs);
                line-height: var(--fs-xs);
                text-transform: uppercase;
                color: var(--cl-violet);
            }
            & .detailsContainer {
                background: var(--cl-violet-light);
                border-radius: 4px;
                padding: var(--m-m) var(--m-s);
                width: 100%;
                &.internal {
                    background: var(--cl-white);
                }
            }
        }
        & .row {
            display: flex;
            &.alignCenter {
                align-items: center;
            }
            &.justifyContentCenter {
                justify-content: center;
            }
            &.header {
                padding: var(--m-s);
                border-bottom: 2px solid var(--cl-gray-light);
            }
            &.details {
                width: 100%;
                justify-content: space-between;
                margin-bottom: var(--m-l);
                &.last {
                    margin-bottom: 0;
                }
            }
            &.info {
                padding: var(--m-xl) var(--m-m);
            }
            &.gap {
                column-gap: var(--m-s);
            }
            &.bigGap {
                column-gap: var(--m-l);
            }
            &.start {
                justify-content: flex-start;
            }
        }
        & .quotationRow {
            display: flex;
            justify-content: flex-end;
            margin-top: calc((-1) * var(--m-m));
            margin-bottom: var(--m-xl);
            padding: 0 var(--m-s);
            & .quotationText {
                font-size: var(--fs-s);
                font-weight: var(--fw-regular);
                color: var(--cl-black);
            }
        }
        & .w100 {
            width: 100%;
        }
        & .w50 {
            width: 50%;
        }
        & .textCenter {
            text-align: center;
        }
        & .mrL {
            margin-right: var(--m-l);
        }
        & .mrXs {
            margin-right: var(--m-xs);
        }
        & .mrXl {
            margin-right: var(--m-xl);
        }
        & .mbXl {
            margin-bottom: var(--m-xl);
        }
        & .mbL {
            margin-bottom: var(--m-l);
        }
        & .mtXl {
            margin-top: var(--m-xl);
        }
        & .gridMargin {
            margin-top: 9.75px;
        }
        & .mainPadding {
            padding: 0 var(--m-s);
        }
        & .buttonsPadding {
            padding: 0 var(--m-s) var(--m-s) var(--m-s);
        }
        & .qr {
            margin: auto;
            border-radius: 8px;
        }
        & .pointer {
            cursor: pointer;
        }
        & .copyRow {
            display: flex;
            align-items: center;
            column-gap: var(--m-xs);
            & .copyIconWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: var(--m-xs);
                border-radius: 50%;
                background-color: var(--cl-gray-light);
                & .copyIcon {
                    width: 14px;
                    height: 14px;
                    color: var(--cl-gray);
                }
            }
        }
        & .buttonsRow {
            display: flex;
            column-gap: var(--m-s);
            margin-top: var(--m-xl);
            max-width: 37.5%;
            margin-left: auto;

            & .button {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        & .buttonPadding {
            padding: var(--m-s);
        }
        & .buttonRow {
            display: flex;
            align-items: center;
            padding: 0 var(--m-m);
            column-gap: var(--m-xs);
        }
    }
}
</style>
